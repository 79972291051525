import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "carbonation"
    }}>{`Carbonation`}</h1>
    <h2 {...{
      "id": "process"
    }}>{`Process`}</h2>
    <ul>
      <li parentName="ul">{`Use the liquid socket of keg to pump the CO2, which leads to better contact of beer and CO2.`}</li>
      <li parentName="ul">{`slowly turn on the gas cylinder to 5 psi and check for any leaks in the line,`}</li>
    </ul>
    <h2 {...{
      "id": "with-priming-sugar"
    }}>{`With Priming Sugar`}</h2>
    <p>{`Corn Sugar: mostly dextrose, it’s favored for its high fermentability, low moisture content, and neutral flavor.`}</p>
    <p>{`Dextrose, sometimes known as glucose, is a very simple sugar derived from cornstarch and is very similar in consistency to powdered sugar. Though, being made from corn rather than cane sugar, there is little to no flavor added when using dextrose in brewing recipes.`}</p>
    <p>{`Brown sugar is available in light and dark varieties and can offer some caramel notes to your finished beer. Be careful: Some cheap brown sugars are just table sugar that has had molasses and caramel coloring added. Look for natural brown sugar, which features naturally occurring molasses instead of post-production additives.
from `}<a parentName="p" {...{
        "href": "https://beerandbrewing.com/priming-sugar-alternatives/"
      }}>{`beerandbrewing`}</a></p>
    <h2 {...{
      "id": "how-much-sugar"
    }}>{`How much sugar?`}</h2>
    <p>{`Residual Carbonation Level + Priming Carbonation Levels = Target Carbonation Level`}</p>
    <p>{`use calculator `}<a parentName="p" {...{
        "href": "https://www.brewersfriend.com/beer-priming-calculator/"
      }}>{`here`}</a></p>
    <p>{`normally 100g glucose to 20 Liter beer`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      